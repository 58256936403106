<template>
  <div id="navbar">
        <div class="left">
            <router-link to="/" class="ld-logo"><strong>LT</strong></router-link>
        </div>
        <div class="right">
            <router-link to="/about">A<sub>bout</sub></router-link>
            <router-link to="/contact">C<sub>ontact</sub></router-link>
            <router-link to="/projects">P<sub>rojects</sub></router-link>
            <router-link to="/blog">B<sub>log</sub></router-link>
        </div>
  </div>
  <div id="navbar-mobile" class="navbar">
        <div class="menu">
            <img @click="goHome()" class="fullname-logo" alt="Luis D Torres P. Brand Name Logo" title="Luis Torres. SysAdmin / Developer" src="@/assets/luisdtorresp-logo.svg">
            <div id="nav-icon" @click="toggleMobileNav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="3rem" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
            </div>
        </div>
        <div id="nav-links" class="nav">
            <hr class="myline">
            <ul>
                <li><router-link to="/about" tag="li">/about</router-link></li>
                <li><router-link to="/contact">/contact</router-link></li>
                <li><router-link to="/projects">/projects</router-link></li>
                <li><router-link to="/blog">/blog</router-link></li>
            </ul>
            <hr class="myline">
            
        </div>
  </div>
</template>

<script>
export default {
    name: 'NavHeader',
    methods: {
        toggleMobileNav() {
            const togglerIcon = document.getElementById('nav-links')
            togglerIcon.classList.toggle('active')
        },
        goHome() {
            this.$router.push('/');
        }
    }
}
</script>

<style>
#navbar-mobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    width: 100%;
    z-index: 1000;
}

#navbar-mobile, .menu{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 5rem;
    box-sizing: border-box;
}
.menu{
    padding: 2%;
    width: 100%;
    height: 100%;
}
#navbar-mobile .nav{
    display: block;
    transition: all ease-in-out 0.3s;
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
    
}

#navbar-mobile .nav.active {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    background-color: #e3e3e3;
    padding: 5% 0;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 5rem);
    position: relative;
    top:0
}

#navbar-mobile .nav .myline{
    border: solid 2px #3e3e3e;
    border-radius: 4px;
    width: 60%;
    max-width: 600px;
    margin: 1.2rem auto;
}

#navbar-mobile .nav ul{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
    
}

#navbar-mobile .nav li {
    margin: 2% 0;
    padding: 5% 0;
}

#navbar-mobile .nav li:hover, #navbar-mobile .nav li:focus, #navbar-mobile .nav li:active {
    background-color: #3e3e3e;
}

#navbar-mobile .nav li:hover a, #navbar-mobile .nav li:focus a, #navbar-mobile .nav li:active a {
    color: #fff;
}

#navbar-mobile .nav a{
    color: #3e3e3e;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 1rem;
    margin:1em;
}

#navbar {
    display: none;
}
.nav-icon{
    margin: auto;
}

.fullname-logo{
  margin-left: auto;
  width: 80%;
  max-width: 540px;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 700px) {
    #navbar-mobile{
        display: none;
    }

    #navbar{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        max-width: 100vw;
        height: 15%;
        max-height: 90px ;
    }
    #navbar .left{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 35%;
        
    }
    #navbar .right{
        flex-flow: row nowrap;
        display: flex;
        justify-content: flex-end;
        width: 50%;
    }

    .ld-logo, #navbar a{
        border-radius: 1rem;
        border: solid 4px #3e3e3e ;
        font-size: 2.2rem;
        font-weight: bold;
        padding: 0.8rem;
        text-decoration: none;
        margin: 0.3rem;
        color: #3e3e3e;
        width: 100px;
        max-width: 20%;
    }

    a sub{
        color: #aaa;
        font-size: 0.3em;
    }
}
</style>