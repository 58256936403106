<template>
    <NavHeader/>
    <Development/>
  <footer class="grey">
    <NavFooter/>
  </footer>
</template>

<script>
import NavHeader from '@/components/Navbar.vue'
import NavFooter from '@/components/Footer.vue'
import Development from '@/components/Development.vue'

export default {
  name: 'Blog',
  components: {
    NavHeader,
    NavFooter, 
    Development
  }
}
</script>

<style>
  .grey{
    background-color: #e3e3e3;
  }
   
</style>
